exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cloud-accounting-js": () => import("./../../../src/pages/cloud-accounting.js" /* webpackChunkName: "component---src-pages-cloud-accounting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-outsourcing-services-js": () => import("./../../../src/pages/outsourcing-services.js" /* webpackChunkName: "component---src-pages-outsourcing-services-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tax-services-js": () => import("./../../../src/pages/tax-services.js" /* webpackChunkName: "component---src-pages-tax-services-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

